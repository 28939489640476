import { createRouter,createWebHashHistory } from "vue-router";

const router = createRouter({
    history : createWebHashHistory(),
    routes : [
        {
            path : '/',
            redirect : '/home',
        },
        {
            path : '/login',
            component : ()=>import('../components/Login.vue'),
        },
        {
            path : '/home',
            component : ()=>import('../components/Thome.vue'),
        },
        {
            path : '/mine',
            component : ()=>import('../components/Tmine.vue'),
        },
        {
            path : '/hello',
            component : ()=>import('../components/HelloWorld.vue'),
        },
        {
            path : '/shopdetail',
            component : ()=>import('../components/ShopDetail.vue'),
        },
        {
            path : '/kcf',
            name : 'kcf',
            component : ()=>import('../components/Zzcf.vue'),
        },
        {
            path : '/stcf',
            component : ()=>import('../components/Stcf.vue'),
        },
        {
            path : '/pxcs',
            component : ()=>import('../components/pscx.vue'),
        },
        {
            path : '/yycs',
            component : ()=>import('../components/yycx.vue'),
        },
        {
            path : '/pxcsdetail',
            component : ()=>import('../components/pscxdetail.vue'),
        },
        {
            path : '/address',
            component : ()=>import('../components/Address.vue'),
        },
        {
            path: '/addressedit',
            component: () => import('../components/AddressEdit.vue'),
        },
        {
            path: '/ShopZZ',
            component: () => import('../components/ShopZZ.vue'),
        }

    ]
});

export default router;