<template>
  <router-view></router-view>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import { ref, provide} from 'vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  setup() {
    let name = ref("father")
    provide("dd",name);
    function handleclick(item) {
      console.log(item)
    }

    return {
      handleclick,
    }
  }
}
</script>

<style>

</style>
