<template>
  <h1>zhongyao</h1>
  <!-- <two></two> -->
  <!-- <h2>name {{name}} </h2>
  <h2>age {{age}}</h2>
  <h3>{{school.sname}}</h3>
  <h3>{{school.address}}</h3>
  <button @click="test" >TAP</button>
  <button @click="changePerson">person</button>
  <button @click="changeSchool">school</button>
  <button @click="changeList">changeLists</button>
  <button @click="testclick">testclick</button> -->

  <button @click="test" >TAP</button>
</template>

<script>
import { reactive, toRefs } from "vue"

export default {
  name: 'HelloWorld',
  props: ["valss"],
  emits: ["dd"],
  components: {
   
  },
  setup(props, context) {
    console.log(props.valss)
    let data = reactive({
      name: "shick",
      age: 33,
      school: {
        sname: "chuanda",
        address: "chengdu"
      },
      list: ["11", "22", "33"],
    })

    function testclick() {
      context.emit("dd", "子组件的值")
    }

    function changePerson() {
      data.name = "smallone",
        data.age = 18
    }

    function changeSchool() {
      data.school.sname = "chengda",
        data.school.address = "pixian"
    }

    function test() {
      showToast('提示内容');
      // alert(`my name is ${data.name},age is ${data.age}`)
    }

    function changeList() {
      data.list[2] = "999"
    }

    return {
      ...toRefs(data),
      test,
      changePerson,
      changeSchool,
      changeList,
      testclick,
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
