import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible'
// 1. 引入你需要的组件
// import { Button } from 'vant'
// import { Popup } from 'vant'
// import { Toast } from 'vant'
// import { Form } from 'vant'
// import { Field } from 'vant'
// import { NoticeBar } from 'vant'
// import { Area } from 'vant'
import * as vant from 'vant';
import { Toast } from 'vant';
// 2. 引入组件样式
import 'vant/lib/index.css'
import 'vant/es/toast/style';
import '../src/css/base.less';

// 引入路由
import router from './router/index'

const app = createApp(App)
// app.use(Button)
// app.use(Popup)
app.use(Toast)
// app.use(Form)
// app.use(Field)
// app.use(NoticeBar)
// app.use(Area)

// 注册所有 Vant 组件
Object.keys(vant).forEach(key => {
    app.use(vant[key]);
  });

app.use(router)
app.mount('#app')
